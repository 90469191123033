<template>
  <div class="row">
    <div class="col-xl-9">
      <div class="card">
        <div class="card-header card-header-flex align-items-center">
          <div class="d-flex flex-column justify-content-center mr-auto">
            <h5 class="mb-0">
              <strong>{{ $t("sms.boiteEnvoieSms") }}</strong>
            </h5>
          </div>
          <div>
            <a href="javascript: void(0);" class="btn btn-md btn-light mr-2">
              <json-csv
                style="width: 100%"
                :fields="fields"
                :labels="labels"
                :data="csvTable"
                :name="'Boite d\'envoie.csv'"
                ><a-icon
                  class="anticon mt-2"
                  style="color: green"
                  type="file-excel"
                /><span>CSV</span></json-csv
              >
            </a>

            <a-button type="primary" class="btn" @click="imprimer"
              ><a-icon type="printer" />{{ $t("action.imprimer") }}</a-button
            >
          </div>
        </div>

        <div class="card-body">
          <a-table
            @change="tableChanged"
            rowKey="_id"
            :pagination="true"
            :data-source="data"
            :columns="columns"
            :loading="tableLoading"
            :scroll="{ x: 'max-content' }"
          >
            <template slot="date" slot-scope="text">
              {{ moment(text).format("DD/MM/YYYY hh:mm:ss") }}
            </template>
            <div slot="tags" slot-scope="text, record">
              <div class="d-block">
                <span v-for="item in record.tags" :key="item">
                  <a-tag color="blue">
                    {{ item }}
                  </a-tag>
                </span>
              </div>
            </div>
            <div slot="target" slot-scope="text">
              <div class="d-block">
                <span v-for="item in text" :key="item">
                  <a-tag color="blue" v-if="item === 'father'">
                    {{ $t("sms.pere") }}
                  </a-tag>
                  <a-tag color="pink" v-else-if="item === 'mother'">
                    {{ $t("sms.mere") }}
                  </a-tag>
                  <a-tag color="purple" v-else>
                    {{ $t("sms.specifique") }}
                  </a-tag>
                </span>
              </div>
            </div>
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
                >{{ $t("paiement.chercher") }}</a-button
              >
              <a-button
                size="small"
                style="width: 90px"
                @click="() => handleReset(clearFilters)"
                >{{ $t("action.reinitialiser") }}</a-button
              >
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template
              slot="expandedRowRender"
              slot-scope="record"
              style="margin: 0"
            >
              <label class="font-weight-bold">{{ $t("sms.message") }} :</label>
              <p>{{ record.message }}</p>
              <div v-if="record.customNumbers">
                <label class="font-weight-bold"
                  >{{ $t("sms.numsSpecifiques") }} :</label
                >
                <p>{{ record.customNumbers }}</p>
              </div>
              <label
                class="font-weight-bold"
                v-if="record.parents && record.parents.length > 0"
                >{{ $t("sms.listeParents") }} :</label
              >

              <a-list
                v-if="record.parents && record.parents.length > 0"
                :grid="{ gutter: 16, column: 3 }"
                :data-source="record.parents"
                size="small"
              >
                <a-list-item slot="renderItem" slot-scope="item">
                  <a-list-item-meta
                    v-if="
                      record.target.includes('father') &&
                      record.target.includes('mother')
                    "
                    :description="
                      item.father.phone + ' -- ' + item.mother.phone
                    "
                  >
                    <span slot="title">{{
                      item.father.lastName + " " + item.father.firstName
                    }}</span>
                    <a-avatar
                      size="large"
                      slot="avatar"
                      v-if="item.photo"
                      :src="settings.base_url + '/' + item.photo"
                    />
                    <a-avatar
                      v-else
                      size="large"
                      slot="avatar"
                      style="color: #f56a00; background-color: #fde3cf"
                    >
                      {{ item.father.firstName[0].toUpperCase() }}
                    </a-avatar>
                  </a-list-item-meta>
                  <a-list-item-meta
                    v-else-if="record.target.includes('father')"
                    :description="item.father.phone"
                  >
                    <span slot="title">{{
                      item.father.lastName + " " + item.father.firstName
                    }}</span>
                    <a-avatar
                      slot="avatar"
                      size="large"
                      v-if="item.photo"
                      :src="settings.base_url + '/' + item.photo"
                    />
                    <a-avatar
                      slot="avatar"
                      size="large"
                      style="color: #f56a00; background-color: #fde3cf"
                      v-else
                    >
                      {{ item.father.firstName[0].toUpperCase() }}
                    </a-avatar>
                  </a-list-item-meta>
                  <a-list-item-meta v-else :description="item.mother.phone">
                    <span slot="title">{{
                      item.mother.lastName + " " + item.mother.firstName
                    }}</span>
                    <a-avatar
                      size="large"
                      slot="avatar"
                      v-if="item.photo"
                      :src="settings.base_url + '/' + item.photo"
                    />
                    <a-avatar
                      size="large"
                      slot="avatar"
                      style="color: #f56a00; background-color: #fde3cf"
                      v-else
                    >
                      {{ item.mother.firstName[0].toUpperCase() }}
                    </a-avatar>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </template>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>{{ text }}</template>
            </template>
          </a-table>
        </div>
      </div>
    </div>
    <div class="col-xl-3">
      <div class="row">
        <div class="card" style="width: 100%">
          <messageChart :data="rowData" />
        </div>
      </div>
      <div class="row">
        <div class="card" style="width: 100%">
          <messageParTagChart :data="rowData" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
import messageChart from "./components/messageChart";
import messageParTagChart from "./components/messageParTagChart";

var datePicker = "";
/*
data : {
    nomClient,
    enfant,
    date,
    montant
    tags:Array of keysOf(recipeTypes or/and userDefinedRecipes)
}
*/
const moneyQuery = { status: { $ne: "cancelled" } };

export default {
  components: {
    JsonCsv,
    messageChart,
    messageParTagChart,
  },
  async created() {
    this.tableLoading = true;
    await apiClient
      .get("/sms")
      .then((res) => {
        this.data = res.data;
        this.rowData = this.data;
      })
      .catch((e) => {
        console.error(e.stack);
        this.$message.warning(this.$t("warning.connectionProblem"));
      });

    this.tableLoading = false;
    this.filtredTable = [...this.data];
  },
  computed: {
    /**
     *         arr.push(this.filtredTable[i].user.userName);
        arr.push(this.filtredTable[i].message);
        arr.push(
          moment(this.filtredTable[i].createdAt).format("DD/MM/YYYY hh:mm:ss")
        );
        arr.push(this.filtredTable[i].totalSMSRSent);
     */
    ...mapState(["settings"]),
    csvTable() {
      const data = this.filtredTable.map((item) => {
        let returnValue = { ...item };
        returnValue.userName = returnValue.user.userName;
        returnValue.message = returnValue.message;

        returnValue.createdAt = moment(returnValue.createdAt).format(
          "DD/MM/YYYY hh:mm:ss"
        );

        returnValue.totalSMSRSent = returnValue.totalSMSRSent;

        return returnValue;
      });
      return data;
    },
  },
  data() {
    return {
      labels: {
        userName: "Nom d'utlisateur",
        message: "Message",
        createdAt: "Date",
        totalSMSRSent: "Nombre d'SMS envoyé",
      },
      fields: ["userName", "message", "createdAt", "totalSMSRSent"],
      datePicker,
      data: [],
      rowData: [],

      columns: [
        {
          title: this.$t("sms.envoyeParUser"),
          dataIndex: "user.userName",
          key: "userName",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) => {
            if (record.user.userName)
              return record.user.userName
                .toLowerCase()
                .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          //Mots clés
          title: this.$t("sms.groupe"),
          dataIndex: "target",
          key: "target",
          scopedSlots: {
            customRender: "target",
          },
        },
        {
          //Mots clés
          title: this.$t("sms.tags"),
          dataIndex: "tags",
          key: "tags",
          scopedSlots: {
            customRender: "tags",
          },
        },
        {
          title: this.$t("sms.envoiDate"),
          dataIndex: "createdAt",
          key: "createdAt",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.createdAt)
              .format("DD/MM/YYYY hh:mm:ss")
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("sms.nombreSms"),
          dataIndex: "totalSMSRSent",
          key: "total",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
          },
          onFilter: (value, record) => record.totalSMSRSent == value,
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      columnsExpanded: [
        {
          title: "Destinataire",
          dataIndex: "father",
          key: "parent",
          scopedSlots: {
            customRender: "parent",
          },
        },
        {
          title: "Téléphone",
          dataIndex: "father.phone",
          class: "bg-transparent text-gray-6",
          scopedSlots: {
            customRender: "phone",
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    moment,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },

    imprimer() {
      this.$gtag.event("Imp SMS Historique", {
        event_category: "Impression PDF",
        event_label: "sms:Historique section",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      var titre = "Boite d'envoi ";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      let totalSMS = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(this.filtredTable[i].user.userName);
        arr.push(this.filtredTable[i].message);
        arr.push(
          moment(this.filtredTable[i].createdAt).format("DD/MM/YYYY hh:mm:ss")
        );
        arr.push(this.filtredTable[i].totalSMSRSent);
        totalSMS += this.filtredTable[i].totalSMSRSent;
        array.push(arr);
        arr = [];
      }
      doc.autoTable({
        styles: {
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Nom d'utlisateur", "Message", "Date", "Nombre d'SMS envoyé"]],
        body: array,
        foot: [
          [
            { colSpan: 3, content: "Total", styles: { halign: "center" } },
            totalSMS,
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Etat des recettes.pdf");
    },

    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
  },
};
</script>
